import { RequestConfig } from 'packages/http-client/fetcher';

export const isLimitedUseAccessToken = (): boolean => {
  /**
   * While accessing handoff url for secure upload we saved handoff_purpose and access_token in sessionstorage
   * and fetching its value. The access token saved in session storage having limited access functionality, so
   * sending new key as handoffPurpose in requestConfig.opts by matching its value with cip_document_validation
   * so that we will not send access_token in api if value is true.
   */
  const win = window as Window;
  const cip_document_validation = 'cip_document_validation';
  const hasLimitedUseAccessToken: boolean =
    win.sessionStorage.getItem('acp_handoff_purpose') &&
    JSON.parse(
      atob(win.sessionStorage.getItem('acp_handoff_purpose') as string)
    ) === cip_document_validation
      ? true
      : false;

  return hasLimitedUseAccessToken;
};

export const limitedUseAccessToken = <T extends RequestConfig>(
  requestConfig: T
): T => {
  return isLimitedUseAccessToken()
    ? {
        ...requestConfig,
        opts: {
          ...requestConfig.opts,
          handoffPurpose: true
        }
      }
    : requestConfig;
};
